<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href="https://mysteryescaperoom.com">
            Mystery Escape Room
          </a>
        </li>
      </ul>
      <div class="copyright">
        © {{year}} by <a href="https://www.aivector.com" target="_blank" rel="noopener">AI Vector</a>&nbsp;&
        <a href="https://mysteryescaperoom.com" target="_blank" rel="noopener">Mystery Escape Room</a>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  };
</script>
<style>
</style>
