<template>
  <card>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <label for="username">Username</label>
            <br>
            <input type="text" name="username" id="username" v-model="login.username">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <label for="password">Password</label>
            <br>
            <input type="password" name="password" id="password" v-model="login.password"
                   @keyup.enter="onSubmit">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <button class="btn btn-primary" @click="onSubmit">Submit</button>
          </div>
        </div>
      </div>
  </card>
</template>

<script>
import { CommonAPIService } from '../../servicehandlers/CommonHandler';
import loginCheckMixin from '@/mixin';

const apiService = new CommonAPIService();

export default {
  name: 'Login',
  mixins: [loginCheckMixin],
  data: () => ({
    state: null,
    login: {},
    errors: [],
    groups: []
  }),

  methods: {
    goToHome() {
      // this.rightsArray = this.loginCheckMixin(['page-claim', 'page-firm']);
      // let homePage=this.$store.state.userPreferences.home === 'firms' ? 'FirmsSearch' : 'ClaimsSearch';
      let homePage = 'calendar';
      // homePage is preferred page if user has rights to see, else other search
      // based on all having at least one of these two page rights
      this.$router.push({
        name: homePage
      });
    },

    // manual submit login detected.
    onSubmit() {
      let loggedIn = this.$store.getters.loggedIn;
      apiService.login(this.login, this.$router, this.$store, this.errors)
      .then(() => {
        loggedIn = this.$store.getters.loggedIn;
        if (loggedIn) {this.goToHome()}
      })
      .catch((error) => {
        this.errors.push(error);
      });
    }
  },
}
</script>

<style>
input {
  text-align: left;
}
</style>

