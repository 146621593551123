import axios from 'axios';

export class CommonAPIService {
  constructor() {
    // eslint-disable-next-line no-console
    // console.debug('API_URL', process.env.VUE_APP_API_URL);

    this.api_url = process.env.VUE_APP_API_URL;
    this.loggedIn = false;
  }

  signup(login, router, errors) {
    console.log('Signup called');
    return axios.post(`${this.api_url}/auth/signup`, login)
    .then((response) => {
      localStorage.setItem('jwtToken' + '', response.data.user);
    })
    .catch((e) => {
      errors.push(e);
      if (e.response.status === 401) {
        router.push({
          name: 'Login',
        });
      }
    });
  }

  // main login method for the code
  login(login, router, store, errors) {
    console.log('Login called');
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    return axios.post(`${this.api_url}/auth/signin`, login)
      .then((response) => {
        console.log('Response: ', response.data);
        if (response.data.success === true) {
          localStorage.setItem('jwtToken' + '', response.data.token);
          store.dispatch('SET_LOGGED_IN', true);
          router.push({
            name: 'dashboard',
          });
        }
      })
      .catch((e) => {
        errors.push(e);
        if (e.response.status === 401) {
          router.push({
            name: 'Login',
          });
        }
      });
  }

  // cookie login method if dominioninsurance was logged in prior to this
  cookieLogin(router, store, errors) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    const parms = { cookieLogin: true };
    return axios.post(`${this.api_url}/api/auth/login`,
      parms, { withCredentials: true })
      .then((response) => {
        localStorage.setItem('jwtToken' + '', response.data.token);
        store.dispatch('ADD_USERID', response.data.user.id);
        store.dispatch('ADD_USERNAME', response.data.user.username);
        store.dispatch('ADD_USER_GROUPS', response.data.user.auth_groups);
        store.dispatch('SET_LOGGED_IN', true);
        /*
        if (router.currentRoute.name === 'Login') {
          router.push({
            name: 'Home',
          });
        }
         */
      })
      .catch((e) => {
        errors.push(e);
        if (e.response.status === 401) {
          router.push({
            name: 'Login',
          });
        }
      });
  }

  postCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    const fullUrl = this.api_url + url;
    return axios.post(fullUrl, parameters)
      .then((response) => {
        // console.debug('postCall response', response);
        return response.data
      })
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'Login',
          });
        }
        throw error
      });
  }

  fileCall(url, files, router) {
    const fullUrl = this.api_url + url;
    return axios.post(fullUrl, files,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': localStorage.getItem('jwtToken'),
        }
      })
      .then((response) => {
      return response
      // return response.data
    })
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'Login',
          });
        }
        throw error
      });
  }

  deleteCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    const fullUrl = this.api_url + url;
    return axios.delete(fullUrl, parameters)
      .then(response => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'Login',
          });
        }
      });
  }

  putCall(url, parameters, router) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    const fullUrl = this.api_url + url;
    return axios.put(fullUrl, parameters)
      .then(response => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'Login',
          });
        }
        throw error;
      });
  }

  getCall(url, parameters, router) {
    // console.log('in getCall w url', url)
    axios.defaults.headers.common.Authorization = localStorage.getItem('jwtToken');
    const fullUrl = this.api_url + url;
    return axios.get(fullUrl, parameters)
      .then(response => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          router.push({
            name: 'Login',
          });
        }
        // throw error
      });
  }
}

export default CommonAPIService;
