import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue");
// const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
// const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue");
// const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
// const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
// const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
// const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
const Calendar = () => import(/* webpackChunkName: "common" */ "@/pages/Calendar.vue");
const Roster = () => import(/* webpackChunkName: "common" */ "@/pages/Roster.vue");
const Login = () => import(/* webpackChunkName: "common" */ "@/pages/Login.vue");
const Signup = () => import(/* webpackChunkName: "common" */ "@/pages/Signup.vue");
const Rooms = () => import(/* webpackChunkName: "common" */ "@/pages/Rooms.vue");
const Shifts = () => import(/* webpackChunkName: "common" */ "@/pages/Shifts.vue");
const Incase = () => import(/* webpackChunkName: "common" */ "@/pages/Incase.vue");
const Files = () => import(/* webpackChunkName: "common" */ "@/pages/Files.vue");
const Payroll = () => import(/* webpackChunkName: "common" */ "@/pages/Payroll.vue");
const Positions = () => import(/* webpackChunkName: "common" */ "@/pages/Positions.vue");
const Locations = () => import(/* webpackChunkName: "common" */ "@/pages/Locations.vue");
const Users = () => import(/* webpackChunkName: "common" */ "@/pages/Users.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "calendar",
        name: "calendar",
        component: Calendar
      },
      {
        path: "roster",
        name: "roster",
        component: Roster
      },
      {
        path: "rooms",
        name: "rooms",
        component: Rooms
      },
      {
        path: "shifts",
        name: "shifts",
        component: Shifts
      },
      {
        path: "incase",
        name: "incase",
        component: Incase
      },
      {
        path: "files",
        name: "files",
        component: Files
      },
      {
        path: "payroll",
        name: "payroll",
        component: Payroll
      },
      {
        path: "positions",
        name: "positions",
        component: Positions
      },
      {
        path: "locations",
        name: "locations",
        component: Locations
      },
      {
        path: "users",
        name: "users",
        component: Users
      }
    ]
  },{
    path: "/login",
    name: "Login",
    component: Login
  },{
    path: "/signup",
    name: "Sign Up",
    component: Signup
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
